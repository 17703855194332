<template>
  <div style="position: relative;">
    <transition name="fade" @enter="enter" @leave="leave">
      <router-view :key="$route.params.id" />
    </transition>
  </div>
</template>
<script>
  import {TweenMax, Power2} from "gsap";
  export default {
    name: "magazineDetail",
    methods: {
      enter(el, done) {
        TweenMax.fromTo(
          el,
          0.6,
          {
            x: "100%",
            opacity: 0
          },
          {
            x: "0%",
            opacity: 1,
            delay: 0.2,
            ease: Power2.easeInOut,
            onComplete: () => {
              done();
            }
          }
        );
      },
      leave(el, done) {
        window.scrollTo({top: 0, behavior: "smooth"});
        TweenMax.fromTo(
          el.querySelector(".btn-prev-top"),
          0.2,
          {
            opacity: 1
          },
          {
            opacity: 0,
            ease: Power2.easeInOut,
            onComplete: () => {
              TweenMax.fromTo(
                el,
                0.6,
                {
                  x: "0%",
                  opacity: 1
                },
                {
                  x: "-100%",
                  opacity: 0,
                  ease: Power2.easeInOut,
                  onComplete: () => {
                    done();
                  }
                }
              );
            }
          }
        );
      }
    }
  };
</script>
<style lang="scss" type="text/css">
  .fade-enter-active {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
  }
</style>
